import React, { Fragment, ReactNode, useState } from 'react'
import { Order, OrderStatus } from 'src/hooks/data/orders/useOrders.ts'
import OrderRowItem from './OrderRowItem.tsx'
import moment from 'moment'
import classNames from 'src/tools/classNames.js'
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext.tsx'
import { LocationType } from 'src/hooks/data/locations/useLocations.ts'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'

type Props = {
  orders: Order[];
  selectedOrderId: string;
  markets: MarketType[];
  locations: LocationType[];
  connections: { name: string; id: string }[];
  view: 'rows' | 'list';
  onOrderClick?: (orderId: string) => void;
  refreshOrders?: () => void;
}


/**
 * This component displays the list of given orders. It has a row and list view mode.
 * Row mode has more data and larger items. List mode is more compact.
 * Row mode is also grouped by delivery date.
 */
export default function OrderList({
  orders,
  selectedOrderId,
  markets,
  locations,
  connections,
  view,
  onOrderClick = () => { },
  refreshOrders = () => { },
}: Props) {
  const user = useCurrentUser();
  const isInstaller = user?.company?.type === "installer";
  const isDistributor = user?.company?.type === "distributor";

  // TODO: type fix
  const groupings = groupOrders(orders);
  // TODO: improve view? or reduce duplicate code?

  // No orders, show "no orders" message
  if ((locations || markets) && orders && orders.length === 0) {
    return <div className="flex items-center justify-center">
      <p className="pt-20 text-sm text-gray-500">No Orders Found</p>
    </div>
  }

  // ----------------- //
  // --- ROWS VIEW --- //
  // ----------------- //
  let mainContent = view === "rows" ? <div
    className={classNames(
      // Grid layout: Name/address/status, badges/dates, BOM/Quote/amount labels, BOM/Quote/amount values, PO#/SO# (>=2xl) labels, PO#/SO# values (>=2xl), actions
      "grid 2xl:grid-cols-[repeat(2,1fr),repeat(5,auto)] grid-cols-[repeat(2,1fr),repeat(3,auto)] gap-x-3",
    )}
  >
    {/* Groups */}
    {
      (locations != null || markets != null) &&
      groupings.map(grouping => {
        if (grouping.orders.length === 0) return; // Skip if no orders
        return (
          <Fragment key={grouping.label}
          >
            {/* Group Header */}
            <h2 className="py-3 pl-5 text-sm font-semibold col-span-full">
              {!["Past Due", "Delivered"].includes(grouping.label) ? `Deliver ${grouping.label}` : grouping.label}
            </h2>
            {/* Group Items */}
            {
              grouping.orders.map(order => <OrderRowItem
                key={order._id}
                order={order}
                market={markets?.find(m => m._id === order.marketId)}
                location={locations?.find(l => l._id === order.locationId)}
                connection={connections.find(c => c.id === (isInstaller ? order.distributorId : order.installerId))}
                view={view}
                isSelected={order._id === selectedOrderId}
                onClick={() => onOrderClick(order._id)}
                onAction={(action) => {
                  refreshOrders();
                }}
              />)
            }
          </Fragment>
        )
      })
    }
  </div>

    // ----------------- //
    // --- LIST VIEW --- //
    // ----------------- //
    : <div className="grid grid-cols-[repeat(7,auto)] gap-x-2 divide-y divide-gray-300">

      {/* Header Row */}
      <div className={classNames(
        // Grid
        "p-2 grid grid-cols-subgrid col-span-full",

        // Styling
        "font-semibold"
      )}>
        <div>Name</div>
        <div>Status</div>
        <div>{isInstaller ? "Market" : "Location"}</div>
        <div>{isInstaller ? "Distributor" : "Installer"}</div>
        <div>Delivery</div>
        <div>Agreement</div>
        <div></div>
      </div>

      {/* List */}
      {
        (locations != null || markets != null) &&
        orders.map(order => <OrderRowItem
          key={order._id}
          order={order}
          market={markets?.find(m => m._id === order.marketId)}
          location={locations?.find(l => l._id === order.locationId)}
          connection={connections.find(c => c.id === (isInstaller ? order.distributorId : order.installerId))}
          view={view}
          isSelected={order._id === selectedOrderId}
          onClick={() => onOrderClick(order._id)}
          onAction={() => {
            refreshOrders();
          }}
        />
        )
      }
    </div>

  // ----------------- //
  // --- RENDERING --- //
  // ----------------- //

  return <>
    {mainContent}

  </>
}

type Group = {
  label: string,
  orders: Order[]
}

// TODO: comment
function groupOrders(orders: Order[]): Group[] {

  const dayMapKeyFormat = 'YYYY-MM-DD';
  let dayMap: { [dayStr: string]: Order[] } = {}
  const pastDueOrders: Order[] = [];
  const deliveredOrders: Order[] = [];
  const today = moment().utc();

  // Categorize orders
  for (let order of orders) {
    //  TODO: handle null date
    let orderDate = moment(order.requestedDelivery.deliveryDate).utc();
    let category = moment(orderDate).format(dayMapKeyFormat);

    // If delivered, add to delivered list and skip day grouping
    if (order.status === OrderStatus.DELIVERED) {
      deliveredOrders.push(order);
      continue;
    }
    // If past due, add to past due list and skip day grouping
    if (today.isAfter(orderDate) && !today.isSame(orderDate, 'day')) {
      pastDueOrders.push(order);
      continue;
    }

    // If not in map, add
    if (!dayMap[category]) {
      dayMap[category] = [];
    }

    // Add to group
    dayMap[category].push(order);
  }

  // Get sorted day keys
  const sortedDayKeys = Object.keys(dayMap).sort((a, b) => {
    return moment(a, dayMapKeyFormat).diff(moment(b, dayMapKeyFormat));
  });

  // Build array of sorted groups
  const groupings: Group[] = sortedDayKeys.map(key => {
    const date = moment(key, dayMapKeyFormat);
    let dayLabel: string;

    // Check today
    if (today.isSame(date, 'day')) {
      dayLabel = "Today";
    }
    // Check tomorrow
    else if (today.clone().add(1, "day").isSame(date, 'day')) {
      dayLabel = "Tomorrow";
    }
    // Check past due
    else if (today.isAfter(date)) {
      dayLabel = "Past Due";
    }
    // Other
    else {
      dayLabel = date.format('dddd M/D/Y');
    }

    return {
      label: dayLabel,
      orders: dayMap[key]
    }
  });

  // If any past due, add past due group at front
  if (pastDueOrders.length > 0) {
    groupings.unshift({
      label: "Past Due",
      orders: pastDueOrders
    });
  }
  // If any delivered, add delivered group at end
  if (deliveredOrders.length > 0) {
    groupings.push({
      label: "Delivered",
      orders: deliveredOrders
    });
  }
  return groupings;
}
