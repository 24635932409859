import React, { useMemo, useState } from 'react'
import OrderList from 'src/components/Orders/OrderList.tsx'
import OrderModal, { OrderModalData } from 'src/components/Orders/OrderModal.tsx'
import OrderStatusList from 'src/components/Orders/OrderStatusList.tsx'
import OrderTimeline from 'src/components/Orders/OrderTimeline.tsx'
import Spinner from 'src/components/Spinner'
import Button from 'src/components/input/Button'
import GridListToggle from 'src/components/input/GridListToggle.tsx'
import MultiSelectListbox from 'src/components/input/MultiSelectListbox.tsx'
import Switch from 'src/components/input/Switch.tsx'
import useMultiConnectionSelect from 'src/hooks/data/connections/useMultiConnectionSelect.ts'
import useMultiLocationSelect from 'src/hooks/data/locations/useMultiLocationSelect.ts'
import useMultiMarketSelect from 'src/hooks/data/markets/useMultiMarketSelect.ts'
import useOrders, { Filters, OrderStatus } from 'src/hooks/data/orders/useOrders.ts'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'
import { User } from 'src/hooks/data/users/useUsersByIds'

type Props = {}


/**
 * Page for showing the list of all orders.
 * Displays a list of selectable status filters, a list of orders, and a timeline of order activity.
 * Orders are selectable to filter the timeline.
 * There are also filters for markets/locations and connections.
 * This page works for both installers and distributors as it dynamically changes
 * the market/location filter based on user type.
 */
export default function OrderListPage({ }: Props) {
  const user = useCurrentUser();
  const connectionTypeString = useMemo(() => {
    // TODO: fix type error
    if (!user?.company?.type) return "Connection";
    return (user as User).company.type === "distributor" ? "Installer" : "Distributor";
  }, [user?.company.type])

  // Currently visible modal (null if none) // TODO: change to enum?
  const [modal, setModal] = useState<"create" | null>(null);

  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatus[]>(null)
  const [markets, selectedMarkets, setSelectedMarkets] = useMultiMarketSelect(true, true);
  const [locations, selectedLocations, setSelectedLocations] = useMultiLocationSelect(true);
  const [connections, selectedConnections, setSelectedConnections] = useMultiConnectionSelect(true);

  const [search, setSearch] = useState<string>();

  // Generate filters object from selected markets, locations, connections, statuses, and search
  const filters: Filters = useMemo<Filters>(() => {
    // If any of the data is not loaded yet, return null so the orders are not fetched.
    // This way the orders aren't fetched without filters. Otherwise orders would be fetchec
    // twice (once without filters and once with filters) which is unnecessary and slow.
    if (
      !connections ||
      !(markets || locations) // Require either markets or locations (based on company type)
    ) return null;

    let marketIds = selectedMarkets?.map(m => m._id);
    let locationIds = selectedLocations?.map(m => m._id);

    // If all markets or locations are selected, don't filter by them
    if (selectedMarkets && markets && selectedMarkets.length === markets.length) marketIds = null;
    if (selectedLocations && locations && selectedLocations.length === locations.length) locationIds = null;

    return {
      marketIds: marketIds,
      locationIds: locationIds,
      connectionIds: selectedConnections?.map(c => c.id),
      search: search,
      statuses: selectedStatuses,
    }
  }, [selectedMarkets, selectedLocations, selectedConnections, search, selectedStatuses]);

  const [orders, statusCounts, refreshOrders] = useOrders(filters);
  const [selectedOrderId, setSelectedOrderId] = useState<string>(null);

  const [view, setView] = useState<"list" | "rows">('list');
  const [hideActivity, setHideActivity] = useState<boolean>(true);


  const DEFAULT_BLANK_ORDER: OrderModalData = {
    installerId: user?.company?.type.toLowerCase() === "installer" ? user.company.id : null,
    contact: {},
    address: {},
  }


  const marketOptions = markets?.map(market => ({ label: market.name, value: market._id })) || [];

  /**
   * Handles setting the selected markets based on the new market IDs.
   */
  function handleSelectedMarketsChange(newMarketIds: string[]) {
    setSelectedMarkets(markets?.filter(m => newMarketIds.includes(m._id)))
  }

  /**
   * Handles setting the selected locations based on the new location IDs.
   */
  function handleSelectedLocationsChange(newLocationIds: string[]) {
    setSelectedLocations(locations?.filter(m => newLocationIds.includes(m._id)))
  }

  /**
   * Handles setting the selected connections based on the new connection IDs.
   */
  function handleSelectedConnectionsChange(newConnIds: string[]) {
    setSelectedConnections(connections?.filter(c => newConnIds.includes(c.id)))
  }

  /**
   * Handles setting the selected statuses based on the new statuses.
   */
  function handleSelectedStatusesChange(newStatuses: OrderStatus[]) {
    setSelectedStatuses(newStatuses)
  }

  /**
   * Handles opening an order creation modal.
   * Requires the user to be in an installer.
   */
  function handleCreateOrderBtn() {
    if (user?.company?.type.toLowerCase() !== "installer") return;

    setModal('create');
  }

  /**
   * Handle opening/closeing order activity based on order selection
   */
  function handleOrderClick(orderId: string) {
    let newSelectedId = selectedOrderId === orderId ? null : orderId;
    // Toggle selected order
    setSelectedOrderId(newSelectedId);
    setHideActivity(!Boolean(newSelectedId)); // If new selected ID is null, hide activity. Else show.
  }

  // TODO: fixed width left and right sections
  // otherwise they can change size when things like status count changes which feels bad for UX
  return (
    <div className="flex p-4 divide-x divide-gray-300 grow">

      {/* Left Section - Filters */}
      <div className="p-4">

        {/* Statuses */}
        <div className="space-y-3">
          <OrderStatusList
            statusCounts={statusCounts}
            selectedStatuses={selectedStatuses}
            onChange={handleSelectedStatusesChange}
          />
        </div>
      </div>

      {/* Middle Section - List */}
      <div className="p-4 overflow-y-auto grow">
        {/* Filters / Actions */}
        <div className="flex items-center gap-3 pb-2">

          {/* Markets */}
          {user?.company?.type === "installer" ?
            <MultiSelectListbox
              itemType="Market"
              options={marketOptions}
              selectedOptionsValues={selectedMarkets?.map(m => m._id) || []}
              onChange={handleSelectedMarketsChange}
            />
            : <MultiSelectListbox
              itemType="Location"
              options={locations?.map(location => ({ label: location.name, value: location._id })) || []}
              selectedOptionsValues={selectedLocations?.map(m => m._id) || []}
              onChange={handleSelectedLocationsChange}
            />
          }

          {/* Distributors */}
          <MultiSelectListbox
            itemType={connectionTypeString}
            options={connections?.map(connection => ({ label: connection.name, value: connection.id })) || []}
            selectedOptionsValues={selectedConnections?.map(c => c.id) || []}
            onChange={handleSelectedConnectionsChange}
          />
          <div className="ml-auto" />

          {/* Search */}
          {/* TODO: implement */}
          {/* <div> */}
          {/*   <SearchBar /> */}
          {/* </div> */}

          {/* View */}
          <GridListToggle
            options={['list', 'rows']}
            selectedOption={view}
            onChange={setView}
          />

          {/* TODO: Settings */}
          {/* <Cog6ToothIcon className="w-6 h-6" /> */}

          {/* Order Creation */}
          {
            user?.company?.type === "installer" &&
            <Button
              variant="primary"
              onClick={handleCreateOrderBtn}
            >
              New Order
            </Button>
          }
        </div>

        {/* List */}
        {orders && (markets || locations) && connections
          ? <OrderList
            orders={orders}
            selectedOrderId={selectedOrderId}
            onOrderClick={handleOrderClick}
            markets={markets}
            locations={locations}
            connections={connections}
            view={view}
            refreshOrders={refreshOrders}
          />
          : <div className="pt-40"><Spinner /></div>}
        {/* TODO: implement better loading state */}
      </div>

      {/* Right Section - History */}
      {
        !hideActivity && <div className="p-4 overflow-y-auto min-w-0 max-w-[280px]">
          <h1 className="text-base font-semibold">Activity</h1>
          {/* TODO: handle empty message? */}
          <OrderTimeline orders={selectedOrderId ? orders?.filter(o => o._id === selectedOrderId) : (orders ?? [])} />
        </div>
      }


      {/* Modals */}
      <OrderModal
        showOrderModal={modal === 'create'}
        setShowOrderModal={(show) => { setModal(show ? 'create' : null) }}
        data={DEFAULT_BLANK_ORDER}
        onSuccess={() => { refreshOrders() }}
        allowMarketSelection
      />
    </div >
  )
}

