import { useEffect, useState } from "react";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import UserManager from "src/tools/UserManager";
import useCurrentUser from "../users/useCurrentUser.ts";

/**
 *  A hook that returns the markets of the specified installer.
 *  If the user is a distributor, the markets of the specified installer are returned.
 *  If the user is an installer, the markets of the user's installer are returned
 *  regardless of the given installerId.
 *  This is mostly useful for getting an installer's markets regardless of
 *  if the user is an installer or distributor.
 *
 *  An example of using this hook is in the `OrderDetailsPage` where both
 *  installers and distributors can view order's market.
 *
 *  Before markets are loaded, `markets` is null.
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [markets] = useMarketsByInstaller(connectedInstallerId);
 *  if (!markets) return <div>Loading...</div>;
 *  return <ul>{markets.map(market => <li>{market.name}</li>)}</ul>;
 *  ```
 */
export default function useMarketsByInstaller(installerId: string): readonly MarketType[] | null {
  const [markets, setMarkets] = useState<MarketType[]>();
  const user = useCurrentUser();

  // Get markets on load
  useEffect(() => {
    if (!user) return; // Ensure user

    let companyType = user.company?.type;

    if (companyType == "installer") {
      UserManager.makeAuthenticatedRequest("/api/markets/installer/find").then(
        res => {
          if (res.data.status === "ok") {
            const markets = res.data.markets;
            setMarkets(markets);
          }
        }
      ).catch(err => {
        console.error(err);
      })
    }

    else if (companyType == "distributor" && installerId) {
      UserManager.makeAuthenticatedRequest(
        `/api/markets/distributor/find?installerId=${installerId}`,
      ).then(
        res => {
          if (res.data.status === "ok") {
            const markets = res.data.markets;
            setMarkets(markets);
          }
        }
      ).catch(err => {
        console.error(err);
      })
    }

  }, [user, installerId]);

  return markets;
}
