import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import { User } from "./useUsersByIds";

/**
 * Returns the users for the specified company of company type.
 * Company must be user's company or connected to user's company.
 *
 * Returns null until users are retrieved from server.
 */
export default function useUsersByCompany(
  companyId: string,
  companyType: "distributor" | "installer"
): User[] | null {
  const [users, setUsers] = useState<User[]>();

  useEffect(() => {
    const abortController = new AbortController();

    let url = `/api/user/find-by-company?type=${companyType}&id=${companyId}`;

    UserManager.makeAuthenticatedRequest(url, "GET", null, { signal: abortController.signal })
      .then(res => {
        if (res.data.status === "ok") {
          setUsers(res.data.users);
        }
      }).catch(err => {
        console.error(err);
      });
    return () => {
      abortController.abort();
    }
  }, [companyId, companyType]);

  return users;
}
