import React, { createElement } from 'react'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import classNames from 'src/tools/classNames';

type Props = {
  alert: AlertType,
  onDismiss: () => void,
}

export type AlertType = {
  title: string,
  subtext: string,
  severity: Severity,
  stamp: Date,
}

export enum Severity {
  GOOD = "good",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

const severityColors = {
  [Severity.GOOD]: {
    bg: "bg-green-50",
    icon: "text-green-400",
    title: "text-green-800",
    text: "text-green-700",
    hover: "hover:bg-green-100",
    focus: "focus:ring-green-600 focus:ring-offset-green-50",
    border: "border-green-400",
  },
  [Severity.INFO]: {
    bg: "bg-blue-50",
    icon: "text-blue-400",
    title: "text-blue-800",
    text: "text-blue-700",
    hover: "hover:bg-blue-100",
    focus: "focus:ring-blue-600 focus:ring-offset-blue-50",
    border: "border-blue-400",
  },
  [Severity.WARNING]: {
    bg: "bg-yellow-50",
    icon: "text-yellow-400",
    title: "text-yellow-800",
    text: "text-yellow-700",
    hover: "hover:bg-yellow-100",
    focus: "focus:ring-yellow-600 focus:ring-offset-yellow-50",
    border: "border-yellow-400",
  },
  [Severity.ERROR]: {
    bg: "bg-red-50",
    icon: "text-red-400",
    title: "text-red-800",
    text: "text-red-700",
    hover: "hover:bg-red-100",
    focus: "focus:ring-red-600 focus:ring-offset-red-50",
    border: "border-red-400",
  },
};

const icons = {
  [Severity.GOOD]: CheckCircleIcon,
  [Severity.INFO]: InformationCircleIcon,
  [Severity.WARNING]: ExclamationTriangleIcon,
  [Severity.ERROR]: XCircleIcon,
};

/**
 * Alert popup to notfiy the user of something.
 * Meant to be used inside of `Alerts`.
 *
 * The `stamp` property is helpful for `Alerts` to identify
 * which alert is which. Just set it as `new Date()`.
 */
export default function Alert({ alert, onDismiss }: Props): JSX.Element {
  const { title, subtext, severity } = alert;
  return (
    <div className={classNames(
      "rounded-md p-4 pointer-events-auto",
      severityColors[severity].bg,
    )}>
      <div className="flex">
        <div className="shrink-0">
          {createElement(icons[severity], {
            className: classNames(
              "h-5 w-5",
              severityColors[severity].icon
            ),
            "aria-hidden": "true",
          })}
        </div>
        <div className="ml-3">
          <h3 className={classNames(
            "text-sm font-medium",
            severityColors[severity].title
          )}>{title}</h3>
          <div className={classNames(
            "mt-2 text-sm",
            severityColors[severity].text
          )}>
            <p>{subtext}</p>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={classNames(
                "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
                severityColors[severity].hover,
                severityColors[severity].focus,
                severityColors[severity].bg,
                severityColors[severity].text,
              )}
              onClick={onDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="size-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
