
import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import useCurrentUser from "../users/useCurrentUser.ts";

export type Truck = {
  _id: string;
  name: string;
}

/**
 * Use this hook to fetch all trucks within the specified distributor.
 * If the user is a distributor, it will fetch all trucks within their distributor
 * regardless of the distributorId parameter.
 */
export default function useTrucksByDistributor(distributorId: string) {
  const [trucks, setTrucks] = useState<Truck[]>();
  const user = useCurrentUser();

  useEffect(() => {
    if (!user) return; // Ensure user

    let companyType = user.company?.type;

    const abortController = new AbortController();

    if (companyType == "distributor") {
      UserManager.makeAuthenticatedRequest("/api/trucks/list", "GET", null, { signal: abortController.signal })
        .then(res => {
          if (res.data.status === "ok") {
            setTrucks(res.data.trucks);
          }
        }).catch(err => {
          console.error(err);
        });
      return () => {
        abortController.abort();
      }
    }

    else if (companyType == "installer" && distributorId) {
      UserManager.makeAuthenticatedRequest(
        `/api/trucks/installer/list?distributorIds=${distributorId}`,
        "GET",
        null,
        { signal: abortController.signal }
      ).then(res => {
        if (res.data.status === "ok") {
          setTrucks(res.data.trucks);
        }
      }).catch(err => {
        console.error(err);
      });
      return () => {
        abortController.abort();
      }
    }
  }, [user, distributorId]);

  return trucks;
}
