import saveAs from 'file-saver'
import { DownloadIcon } from 'lucide-react'
import React, { useState } from 'react'
import { Order } from 'src/hooks/data/orders/useOrders.ts'
import { OrderFileType } from 'src/tools/S3/s3.ts'
import UserManager from 'src/tools/UserManager'
import Button from '../input/Button'
import Spinner from '../Spinner'

type Props = {
  order: Order,
  group: OrderFileType
}

/**
 * This component provides functionality to download images associated with an order.
 * It allows downloading all images or images grouped by a specific type.
 *
 * Props:
 * - order: The order object containing details of the order.
 * - group: The type of image group to download. If null, all images will be downloaded.
 *
 * The component displays a button to trigger the download. While the download is in progress,
 * a loading spinner is shown.
 *
 * Usage:
 * ```ts
 * <DownloadOrderImages order={order} group={group} />
 * ```
 */
export default function DownloadOrderImages({
  order,
  group,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  /**
    * Download all images for the order.
    * Organized into folders by group.
    */
  async function downloadAll(order: Order) {
    setLoading(true);
    try {
      var res = await UserManager.makeAuthenticatedRequest(
        `/api/s3/download-order-images?orderId=${order._id}&all=true`,
        "GET",
        null,
        {
          responseType: "blob"
        }
      )
      saveAs(res.data, `${order.name}_ALL_IMAGES_${Date.now()}.zip`)
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }

  /**
    * Download images for a specific group (e.g. packaging images). 
    */
  async function downloadGroup(order: Order, group: OrderFileType) {
    setLoading(true);
    try {
      var res = await UserManager.makeAuthenticatedRequest(
        `/api/s3/download-order-images?orderId=${order._id}&group=${group}`,
        "GET",
        null,
        {
          responseType: "blob"
        }
      )
      saveAs(res.data, `${order.name}_${group}_${Date.now()}.zip`)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  // Render button for all images
  if (group == null) {
    return !loading ? <Button
      variant="secondary"
      className="flex items-center gap-1"
      onClick={() => downloadAll(order)}
    >
      <DownloadIcon className="w-6 h-6" />
      Download All
    </Button> :
      <div className='flex items-center gap-1 px-4 py-2 rounded-lg border bg-gray-50'>
        <Spinner size={20} />
        <p className="animate-pulse">Downloading</p>
      </div>
  }
  // Render button for specific group
  else {
    return !loading ? <button
      onClick={() => downloadGroup(order, group)}
      className="text-gray-500 hover:text-primary-green"
    >
      <DownloadIcon className="w-5 h-5" />
    </button> :
      <div>
        <Spinner size={20} />
      </div>
  }
}

