import UserManager from "../UserManager"

export type S3File = {
  getObjectSignedUrl: string,
  deleteObjectSignedUrl: string,
}

/**
 * Types of files for an order.
 *
 * Note: not filetype, but purpose of the file.
 */
export enum OrderFileType {
  // Files
  BOM = "BOM",
  QUOTE = "QUOTE",
  PICK_TICKET = "PICK_TICKET",
  ADDITIONAL = "ADDITIONAL",

  // Images
  PACKAGING_IMG = "PACKAGING_IMG",
  DELIVERY_IMG = "DELIVERY_IMG",
  INSTALLER_VERIF_IMG = "INSTALLER_VERIF_IMG",
  OTHER_IMG = "OTHER_IMG",
}

/**
  * S3 bucket name where files are stored
  */
const S3_BUCKET = "toa-order-attachments";

/**
 * Helper class for interacting with S3
 */
export default class S3 {

  /**
   * Gets presigned URLs for the given filepaths
   * Returns empty if no filepaths are provided or if no files are found
   */
  static async get(filepaths: string[], signal: AbortSignal = null): Promise<S3File[]> {

    let url = "/api/s3/get-presigned-urls?"
    url += `bucket=${S3_BUCKET}&`;
    url += `objectKeys=${filepaths.join(",")}`;
    try {
      var res = await UserManager.makeAuthenticatedRequest(url, "GET", null, { signal: signal })
    } catch (error) {
      console.error(error)
    }

    if (res.data.status === "ok" && res.data.presignedUrls?.length) {
      return res.data.presignedUrls
    }

    return []
  }

  /**
   * Gets presigned URLs for the contents of the given folder
   * Returns empty if no files are found
   * @param folderPath The path of the folder to get the contents of
   * @param signal An optional AbortSignal to cancel the request
   * @returns An array of S3File objects
   */
  static async getFolderContents(folderPath: string, signal: AbortSignal = null): Promise<S3File[]> {
    let url = "/api/s3/get-folder-contents?"
    url += `bucket=${S3_BUCKET}&`;
    url += `folderPath=${folderPath}`;
    try {
      var res = await UserManager.makeAuthenticatedRequest(url, "GET", null, { signal: signal })
    } catch (error) {
      console.error(error)
    }

    if (res.data.status === "ok" && res.data.presignedUrls?.length) {
      return res.data.presignedUrls
    }

    return []
  }

  /**
   * Upload the given file to S3 at the given filename/path
   */
  static async upload(file: File, fileName: string): Promise<boolean> {
    // Get upload URL
    try {
      var response = await UserManager.makeAuthenticatedRequest(
        `/api/s3/get-upload-url?bucket=toa-order-attachments&fileName=${fileName}`,
        'GET',
      )
    } catch (error) {
      console.error(error)
      return false;
    }

    // Check if the response is successful
    if (response.data.status !== 'ok') {
      return false;
    }

    // Upload file to S3
    const putObjectSignedUrl = response.data.putObjectSignedUrl
    try {
      await fetch(putObjectSignedUrl, {
        method: 'PUT',
        body: file
      })
    } catch (error) {
      console.error(error)
      return false;
    }

    return true;
  }

  /**
    * Gets the links to display the images for the given order.
    * The images retrieved are based on the type of image (e.g. packaging, delivery, etc.)
    *
    * Usage:
    * ```ts
    * let imageLinks = await S3.getOrderImageLinks(OrderFileType.PACKAGING_IMG, orderId)
    * ...
    * imageLinks.map((link) => <img src={link} />)
    * ```
    */
  static async getOrderImageLinks(type: OrderFileType, orderId: string): Promise<string[]> {
    try {
      var res = await UserManager.makeAuthenticatedRequest(`/api/s3/get-order-images?group=${type}&orderId=${orderId}`, 'GET')
    } catch (error) {
      console.error(error)
    }

    if (res.data.status === "ok" && res.data.presignedUrls?.length) {
      return res.data.presignedUrls;
    }

    return []
  }

} 
