import React from "react";
import useTheme from "../../hooks/styling/useTheme.js";
import Dropdown from "../input/Dropdown.js";

import { SunIcon, ComputerDesktopIcon } from "@heroicons/react/24/outline";

import { MoonIcon } from "@heroicons/react/24/solid";
import useLocalStorage from "../../hooks/useLocalStorage.tsx";

/**
 * A dropdown to select the theme. 
 * Uses useLocalStorage to store the theme.
 * Use the useTheme hook to get the current theme.
 */
export default function StyleThemeSelector(): JSX.Element {
  const [ls_theme, ls_setTheme] = useLocalStorage<string>("theme", "light"); // Default to system theme
  // const [ls_theme, ls_setTheme] = useLocalStorage<string>("theme", null); // Default to system theme
  // TODO: currently default light theme instead of system. Can change to system when dark theme is more polished.
  // see also useTheme hook

  /**
   * Sets the theme in local storage and dispatches a storage event.
   * The storage event is to ensure that all hooks that use the theme are updated.
   * 
   * @param newTheme - The new theme to set to
   * @returns - true if the theme was set, false if the theme was invalid
   */
  function setTheme(newTheme: "dark" | "light" | "system"): boolean {
    if (!["dark", "light", "system"].includes(newTheme)) {
      return false;
    }

    // null represents the system theme
    let newValue = ["dark", "light"].includes(newTheme) ? newTheme : null

    window.dispatchEvent(
      new StorageEvent("storage", {
        key: "theme",
        newValue: JSON.stringify(newValue),
        oldValue: JSON.stringify(ls_theme),
      })
    );

    ls_setTheme(newValue);

    return true;
  }

  const options = [
    [
      {
        label: "Light",
        value: "light",
        icon: SunIcon,
      },
      {
        label: "Dark (Experimental)", // TODO: remove experimental when dark theme is more polished
        value: "dark",
        icon: MoonIcon,
      },
      // TODO: uncomment when dark theme is more polished
      // {
      //   label: "System",
      //   value: "system",
      //   icon: ComputerDesktopIcon,
      // },
    ],
  ];

  return (
    <div>
      <Dropdown
        options={options}
        selectedValue={ls_theme ?? "system"}
        onSelected={(option) => {
          setTheme(option.value);
        }}
      />
    </div>
  );
}
