import React from 'react'
import Alert from './Alert.tsx'
import { AlertType } from './Alert.tsx'

type Props = {
  alerts: AlertType[],
  onDismiss: (a: AlertType) => void,
}

/**
 * Wrapper for displaying alerts to the user.
 * Does not handle state, only displays alerts.
 * The `onDismiss` callback gives the alert that is dismissed.
 * The `stamp` property of alerts is used to uniquely identify alerts.
 * Just set it to `new Date()` when creating alerts.
 */
export default function Alerts({ alerts, onDismiss }: Props) {
  return (
    <div
      className="fixed inset-0 z-50 flex flex-col gap-3 items-center pointer-events-none mt-5"
    >
      {
        alerts.map(a => (
          <Alert
            key={a.stamp}
            alert={a}
            onDismiss={() => onDismiss(a)} // Pass specific alert to Alerts callback
          />
        ))
      }
    </div>
  )
}
