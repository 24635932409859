import React from 'react'
import { OrderAction } from 'src/components/Orders/common/OrderActionButton.tsx';
import AddNoteModal from 'src/components/Orders/modals/AddNoteModal.tsx';
import AddPickTicketModal from 'src/components/Orders/modals/AddPickTicketModal.tsx';
import AddPickTicketAssignTruckModal from 'src/components/Orders/modals/AddPickTicketAssignTruckModal.tsx';
import AddQuoteModal from 'src/components/Orders/modals/AddQuoteModal.tsx';
import ApproveOrderModal from 'src/components/Orders/modals/ApproveOrderModal.tsx';
import AssignTruckModal from 'src/components/Orders/modals/AssignTruckModal.tsx';
import CancelOrderModal from 'src/components/Orders/modals/CancelOrderModal.tsx';
import RescheduleOrderModal from 'src/components/Orders/modals/RescheduleOrderModal.tsx';
import ReviewDateModal from 'src/components/Orders/modals/ReviewDateModal.tsx';
import { Order } from '../data/orders/useOrders.ts';


/**
 * Modals that an order action can use. Maps an OrderAction to a modal component.
 * This makes it easy to display the correct modal for the correct action.
 */
const OrderActionModals: Map<OrderAction, (props: any) => JSX.Element> = new Map([
  // TODO: type of map
  [
    OrderAction.ADD_QUOTE,
    AddQuoteModal
  ],
  [
    OrderAction.APPROVE_ORDER,
    ApproveOrderModal
  ],
  [
    OrderAction.ADD_PICK_TICKET,
    AddPickTicketModal
  ],
  [
    OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK,
    AddPickTicketAssignTruckModal
  ],
  [
    OrderAction.CANCEL_ORDER,
    CancelOrderModal
  ],
  [
    OrderAction.ASSIGN_TRUCK,
    AssignTruckModal
  ],
  [
    OrderAction.RESCHEDULE_ORDER,
    RescheduleOrderModal
  ],
  [
    OrderAction.REVIEW_DATE,
    ReviewDateModal
  ],
  [
    OrderAction.ADD_NOTE,
    AddNoteModal
  ]
])

/**
 * Order action modal hook. This hook is used to open the correct modal for the correct order action.
 * This hook returns a component to render and function to call to run an action on the given order.
 *
 * The callback is called when an action is closed either successfully or not.
 *
 * This makes it easy to display an order action modal by simply calling the function with the order action.
 * Combining this with `OrderActionButton` is the main intended purpose. However, this is separate
 * to allow for other things to call actions on an order. For example, `OrderRowItem` has an `OrderActionButton`
 * but also an `IconDropdown` that both use this to handle the action.
 *
 * Example usage:
 * ```tsx
 * const [orderModals, runAction] = useOrderModals(order, (a) => console.log('Order action complete: ', a));
 * return <>
 *  <OrderActionButton
 *    order={order}
 *    onRunAction={runAction}
 *   />
 *  {orderModals}
 * </>
 * ```
 */
export default function useOrderModals(order: Order, onAction: () => void): readonly [JSX.Element, (a: OrderAction) => void] {
  const [showModal, setShowModal] = React.useState<OrderAction | null>(null);

  /**
   * Functions for the order actions. For now, just adding/approving quote.
   * Most actions will likely just be to open an action modal and note the order to act on.
   */
  const OrderActionFunctions: Map<OrderAction, (o: Order) => void> = new Map([
    [
      OrderAction.ADD_QUOTE,
      (o: Order) => { setShowModal(OrderAction.ADD_QUOTE); }
    ],
    [
      OrderAction.APPROVE_ORDER,
      (o: Order) => { setShowModal(OrderAction.APPROVE_ORDER); }
    ],
    [
      OrderAction.ADD_PICK_TICKET,
      (o: Order) => { setShowModal(OrderAction.ADD_PICK_TICKET); }
    ],
    [
      OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK,
      (o: Order) => { setShowModal(OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK); }
    ],
    [
      OrderAction.CANCEL_ORDER,
      (o: Order) => { setShowModal(OrderAction.CANCEL_ORDER); }
    ],
    [
      OrderAction.ASSIGN_TRUCK,
      (o: Order) => { setShowModal(OrderAction.ASSIGN_TRUCK); }
    ],
    [
      OrderAction.RESCHEDULE_ORDER,
      (o: Order) => { setShowModal(OrderAction.RESCHEDULE_ORDER); }
    ],
    [
      OrderAction.REVIEW_DATE,
      (o: Order) => { setShowModal(OrderAction.REVIEW_DATE); }
    ],
    [
      OrderAction.ADD_NOTE,
      (o: Order) => { setShowModal(OrderAction.ADD_NOTE); }
    ],
  ])

  /**
   * Does the specified order action on the specified order.
   * E.g. `OrderAction.ADD_QUOTE` will open the AddQuoteModal.
   */
  function runAction(action: OrderAction) {
    // TODO: handle null function
    OrderActionFunctions.get(action)(order);
  }

  const ActiveModal = showModal != null ? OrderActionModals.get(showModal) : null;
  const modalComponent = ActiveModal &&
    <ActiveModal
      order={order}
      open
      setOpen={(open: boolean) => {
        if (!open) {
          setShowModal(null)
          onAction?.()
        }
      }}
      onAction={(action: OrderAction) => runAction(action)}
    />

  return [modalComponent, runAction] as const;
}

