import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import useCurrentUser from "../users/useCurrentUser.ts";

export type LocationType = {
  _id: string;
  name: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
  };
}

/**
 *  A hook that returns the locations of the specified distributor.
 *  If the user is an installer, the locations of the specified distributor are returned.
 *  If the user is a distributor, the locations of the user's distributor are returned
 *  regardless of the given distributorId.
 *  This is mostly useful for getting a distributor's locations regardless of
 *  if the user is an installer or distributor.
 *
 *  An example of using this hook is in the `OrderDetailsPage` where both
 *  installers and distributors can view order's location.
 *
 *  Before locations are loaded, `locations` is null.
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [locations] = useLocationsByDistributor(connectedDistributorId);
 *  if (!locations) return <div>Loading...</div>;
 *  return <ul>{locations.map(location => <li>{location.name}</li>)}</ul>;
 *  ```
 */
export default function useLocations(distributorId: string): readonly LocationType[] | null {
  const [locations, setLocations] = useState<LocationType[]>();
  const user = useCurrentUser();

  // Get locations on load
  useEffect(() => {
    if (!user) return; // Ensure user

    let companyType = user.company?.type;

    if (companyType == "distributor") {
      UserManager.makeAuthenticatedRequest("/api/location/distributor/list")
        .then(res => {
          if (res.data.status === "ok") {
            const locations = res.data.locations;
            setLocations(locations);
          }
        }).catch(err => {
          console.error(err);
        })
    }

    else if (companyType == "installer" && distributorId) {
      UserManager.makeAuthenticatedRequest(
        `/api/location/installer/list?distributorIds=${distributorId}`,
      ).then(
        res => {
          if (res.data.status === "ok") {
            const locations = res.data.locations;
            setLocations(locations);
          }
        }
      ).catch(err => {
        console.error(err);
      })
    }
  }, [user, distributorId]);

  return locations;
}
