import moment from 'moment'
import React, { ReactNode } from 'react'
import { Order, Note as NoteType } from 'src/hooks/data/orders/useOrders'
import useUsersByIds, { UserMap } from 'src/hooks/data/users/useUsersByIds.ts'

type Props = {
  order: Order
}

/**
 * Component for displaying a list of notes on an order.
 */
export default function OrderNotes({ order }: Props) {
  const users = useUsersByIds(order?.notes?.map(note => note.addedBy))

  if (!order.notes.length) {
    return <span className="italic text-sm font-normal text-gray-500">No notes</span>
  }

  const notes: NoteType[] = order.notes;
  notes.sort((a, b) => {
    return new Date(a.addedAt).getTime() - new Date(b.addedAt).getTime();
  })

  return <div className="grid gap-2 grid-cols-[auto,1fr] relative">
    {
      order.notes.map((note, index) =>
        <Note note={note} hideLine={index === order.notes.length - 1} users={users} />
      )}
  </div>
}

type NoteProps = {
  note: NoteType,
  hideLine?: boolean,
  users: UserMap,
}

/**
 * Displays a single note for an order.
 * Shows user name and timestamp.
 *
 * Shows a dot and line to the left to indicate
 * a timeline.
 */
function Note({ note, hideLine = false, users }: NoteProps) {
  let stamp = note.addedAt ? moment(note.addedAt) : null;

  // Get user name but skeleton load with random length
  // TODO: can probably improve. randomizes each render which doesn't look great
  let userName: ReactNode = <span className="animate-pulse rounded-full bg-gray-300 w-10 h-4 text-white/0">{Array(Math.ceil(Math.random() * 4) + 5).fill("a")}</span>
  if (users) {
    const userObj = users[note.addedBy];
    userName = userObj ? `${userObj.firstName} ${userObj.lastName.charAt(0)}.` : <span className="italic" > Missing User</span>;
  }

  return (
    <div className="group grid col-span-full grid-cols-subgrid text-sm text-gray-500 font-normal">
      {/* Dot */}
      <div className="flex flex-col gap-1 items-center relative top-1">
        <div className="bg-gray-500 h-3 w-3 rounded-full"></div>
        {!hideLine && <div className="w-0.5 grow bg-gray-300 relative top-0.5"></div>}
      </div>
      {/* Text */}
      <div>
        <p className="text-sm font-normal text-gray-500">
          <span className="font-medium text-gray-900">{userName}</span>
          {" - "}
          {
            stamp ?
              <span>
                <span className='group-hover:hidden'>{stamp.fromNow()}</span>
                <span className='hidden group-hover:inline'>{stamp.format("M/D/Y h:mma")}</span>
              </span>
              : "Missing timestamp"
          }
        </p>
        {note.note}
      </div>
    </div >
  )
}
