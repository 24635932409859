import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { Fragment, createContext, useEffect, useState, useContext } from "react";
import UserContext from "src/contexts/user/UserContext.tsx";
import { OrderStatus, OrderStatusIcons, OrderStatusLabels, StatusSections } from "src/hooks/data/orders/useOrders.ts";
import classNames from "src/tools/classNames";
import UserManager from "src/tools/UserManager";

type StatusListProps = {
  statusCounts: { [status: string]: number };
  selectedStatuses: OrderStatus[];
  onChange: (newStatuses: OrderStatus[]) => void;
}

/**
 * List of order statuses. Each list item is selectable and will call `onChange`.
 * Each list item is defined by a list of `OrderStatus`es. These should be unique to each list item.
 * Selected style applies to a list item if the given `selectedStatuses` match the list for that item.
 * For example, for a folder to be selected, the list of selected must be all statuses in that folder.
 *
 * There are four types of list items:
 * - Group: A major category like "All Orders", "New Orders", "Packaging", "Delivery", and "Other"
 * - Major: A major status like "Ready to Package", "In Transit", etc.
 * - Folder: A folder of minor statuses like "Submitted" containing "Waiting for Quote", "Waiting for Approval", "Adjustments"
 * - Minor: A minor status like "Waiting for Quote", "Waiting for Approval", "Adjustments"
 */
export default function OrderStatusList({ statusCounts, selectedStatuses, onChange }: StatusListProps): JSX.Element {
  const { company } = useContext(UserContext);

  return <div className="grid grid-cols-[auto,1fr,auto] gap-2 relative">

    {/* TODO: vertical bar between all icons/dots. overlapping grid? absolute position? fake it with small bars on each line item? */}

    <GroupStatusItem name="All Orders" statuses={null} selectedStatuses={selectedStatuses} onClick={() => onChange(null)} />
    {StatusSections.map(group => {
      // TODO: fix this recalculating every time
      let allGroupStatuses: OrderStatus[] = [];
      for (let item of group.majorStatuses) {
        if (typeof item === "string") {
          allGroupStatuses.push(item);
        } else {
          allGroupStatuses.push(...item.minorStatuses);
        }
      }
      // TODO: figure out why this is causing a React unique key error
      return (
        <Fragment key={group.name}>
          <GroupStatusItem
            name={group.name}
            selectedStatuses={selectedStatuses}
            statuses={allGroupStatuses}
            onClick={() => onChange(allGroupStatuses)}
            // checking company.settings.orders.packagingVerificationEnabled against false because for installers the packagingVerificationEnabled setting doesnt exist
            disabled={group.name === "Packaging" && company?.settings?.orders?.packagingVerificationEnabled === false}
          />
          {
            group.majorStatuses.map(item => {
              // Major status line item
              if (typeof item === "string") {
                let count = statusCounts[item] ?? 0;
                return <MajorStatusItem
                  key={item}
                  status={item}
                  count={count}
                  selectedStatuses={selectedStatuses}
                  onClick={() => { onChange([item]) }}
                  // checking company.settings.orders.packagingVerificationEnabled against false because for installers the packagingVerificationEnabled setting doesnt exist
                  disabled={group.name === "Packaging" && company?.settings?.orders?.packagingVerificationEnabled === false}
                />
              }

              // Group of minor statuses
              let count = item.minorStatuses.reduce((acc, status) => acc + (statusCounts[status] ?? 0), 0); // Sum counts
              return <>
                <MinorStatusFolderItem
                  key={item.minorGroupLabel}
                  statuses={item.minorStatuses}
                  label={item.minorGroupLabel}
                  Icon={item.minorGroupIcon}
                  count={count}
                  selectedStatuses={selectedStatuses}
                  onClick={() => { onChange(item.minorStatuses) }}
                  onSubClick={(o: OrderStatus) => { onChange([o]) }}
                  minorStatuses={item.minorStatuses}
                  statusCounts={statusCounts}
                />
              </>
            }
            )}
        </Fragment>
      )
    })}
  </div>
}

//  TODO: consider combining these more?

type StatusItemProps = {
  status: OrderStatus;
  count: number;
  selectedStatuses: OrderStatus[];
  onClick?: () => void;
  disabled?: boolean;
}

/**
 * List item for a major status. Shows an icon, the status name, and the count.
 * This is for major statuses like "Ready to Package", "In Transit", etc.
 * 
 * If the status is disabled, the item will be grayed out and not clickable.
 */
function MajorStatusItem({ status, count, selectedStatuses, onClick = () => { }, disabled = false }: StatusItemProps): JSX.Element {

  const isSelected = selectedStatuses?.length === 1 && selectedStatuses[0] === status;
  let Icon = OrderStatusIcons[status]
  return (
    <ListItem
      selected={isSelected}
      onClick={() => {
        if (!disabled) onClick()
      }}
      disabled={disabled}
    >
      <Icon className="w-6 h-6 stroke-2" />
      <p>{OrderStatusLabels[status]}</p>
      <div className="">{count || ""}</div>
    </ListItem>
  )
}

type FolderItemProps = {
  label: string;
  Icon: any;
  count: number;
  statuses: OrderStatus[];
  minorStatuses: OrderStatus[];
  selectedStatuses: OrderStatus[];
  onClick?: () => void;
  onSubClick?: (o: OrderStatus) => void;
  statusCounts: { [status: OrderStatus]: number };
}

/**
 * List item for a folder of minor statuses. Shows an icon, the folder name, and the count.
 * This is collapsable.
 * For example, "Submitted" is a folder item that contains
 * "Waiting for Quote", "Waiting for Approval", and "Adjustments"
 */
function MinorStatusFolderItem({
  label,
  Icon,
  count,
  statuses,
  selectedStatuses,
  onClick = () => { },
  onSubClick = (o: OrderStatus) => { },
  minorStatuses,
  statusCounts
}: FolderItemProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  let statusSet = new Set(statuses)
  let selectedSet = new Set(selectedStatuses)
  let isSelected = statusSet.size === selectedSet.size && statuses?.every(value => selectedSet.has(value));
  // TODO: vertical bar

  const CollapseIcon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return <>
    <ListItem selected={isSelected} onClick={onClick}>
      <Icon className="w-6 h-6 stroke-2" />
      <p>{label}</p>
      <p>{count || ""}</p>
      <div className="absolute inset-y-0 flex items-center justify-center right-full" onClick={(e) => {
        e.stopPropagation(); // Don't select, just collapse
        setIsOpen(!isOpen);
      }}>
        <CollapseIcon className="w-5 h-5 stroke-2" />
      </div>
    </ListItem>
    {isOpen &&
      minorStatuses.map(minS => {
        let count = statusCounts[minS] ?? 0;
        return <MinorStatusItem
          key={minS}
          status={minS}
          count={count}
          selectedStatuses={selectedStatuses}
          onClick={() => { onSubClick(minS) }}
        />
      })
    }
  </>
}

/**
 * List item for a minor status. Shows a circle with a border, the status name, and the count.
 * These appear within `MinorStatusFolderItem` components.
 *
 * For example, "Submitted" is a folder item that contains
 * "Waiting for Quote", "Waiting for Approval", and "Adjustments"
 * as minor status items.
 */
function MinorStatusItem({ status, count, selectedStatuses, onClick = () => { } }: StatusItemProps): JSX.Element {
  const isSelected = selectedStatuses?.length === 1 && selectedStatuses[0] === status;
  return <ListItem noPadding selected={isSelected} onClick={onClick}>
    {/* Circle */}
    <div className="flex items-center justify-end">
      {/* Background */}
      {/* TODO: implement vertical line */}
      {/* <div className="grid items-center justify-center w-5 h-5 bg-gray-100 rounded-full"> */}
      <div className="grid items-center justify-center w-5 h-5 rounded-full">
        {/* Actual Circle */}
        <div className="rounded-full border border-gray-500 w-2.5 h-2.5"></div>
      </div>
    </div>
    <p>{OrderStatusLabels[status]}</p>
    <div className="">{count || ""}</div>
  </ListItem>
}

type GroupStatusItemProps = {
  name: string;
  statuses: OrderStatus[];
  selectedStatuses: OrderStatus[];
  onClick?: () => void;
  disabled?: boolean;
}

/**
  * List item for a group status. Shows a name and a line.
  * This is for major categories like "All Orders",
  * "New Orders", "Packaging", "Delivery", and "Other"
  * 
  * If the status is disabled, the item will be grayed out and not clickable.
  */
function GroupStatusItem({ name, statuses, selectedStatuses, onClick = () => { }, disabled = false }: GroupStatusItemProps): JSX.Element {
  // Set compare statuses with selected (order doesn't matter)
  let statusSet = new Set(statuses)
  let selectedSet = new Set(selectedStatuses)
  let isSelected = statusSet.size === selectedSet.size && statuses?.every(value => selectedSet.has(value));
  if (statuses === null) isSelected = selectedStatuses === null; // For all orders

  return <ListItem
    onClick={() => {
      if (!disabled) onClick()
    }}
    selected={isSelected}
    disabled={disabled}
  >
    <div className="flex items-center col-span-3 gap-3">
      <p className="text-base font-semibold">{name}</p>
      <div
        className={classNames(
          "border-t grow",
          disabled ? "border-gray-400" : "border-gray-600"
        )}
      />
    </div>
  </ListItem>
}

type ListItemProps = {
  selected?: boolean;
  children?: React.ReactNode;
  noPadding?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

/**
 * Base component for list items. Used for all status types/folders/groups.
 * Handles styling and click events.
 * 
 * If the status is disabled, the item will be grayed out and not clickable.
 */
function ListItem({ children, selected = false, noPadding = false, onClick = () => { }, disabled = false }: ListItemProps): JSX.Element {
  return <button
    type="button"
    onClick={onClick}
    data-selected={selected}
    className={classNames(
      // Layout
      "text-left relative col-span-3 grid grid-cols-subgrid whitespace-nowrap",

      // Shape
      "rounded",

      // Padding
      !noPadding && "p-2",

      // Color
      disabled ? "text-gray-400" : "text-gray-600 hover:bg-gray-300 hover:text-gray-800 data-[selected=true]:bg-gray-300",
      disabled ? "dark:text-gray-400" : "dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-white dark:data-[selected=true]:bg-gray-800",

      // Interaction
      disabled ? "" : "cursor-pointer",

      // Group
      "group"
    )}
  >
    {children}
  </button>
}
