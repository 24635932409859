import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import { Order } from "./useOrders";

/**
 * Simple hook to get a single order by ID
 * Returns the given order and a reload function
 * Order is null while loading
 */
export default function useOrder(id: string): readonly [Order | null, () => void] {
  const [order, setOrder] = useState<Order | null>(null);

  function reload() {
    UserManager.makeAuthenticatedRequest(`/api/orders/get?orderId=${id}`)
      .then(res => {
        if (res.data?.status === "ok") {
          setOrder(res.data.order);
        }
      }).catch(err => {
        console.error(err);
      });
  }

  useEffect(() => {
    reload();
  }, [id]);

  return [order, reload] as const;
}
