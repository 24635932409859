import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Fragment, createElement } from "react";

const severityColors = {
  good: {
    bg: "bg-green-50",
    icon: "text-green-400",
    title: "text-green-800",
    text: "text-green-700",
    hover: "hover:bg-green-100",
    focus: "focus:ring-green-600 focus:ring-offset-green-50",
    border: "border-green-400",
  },
  info: {
    bg: "bg-blue-50",
    icon: "text-blue-400",
    title: "text-blue-800",
    text: "text-blue-700",
    hover: "hover:bg-blue-100",
    focus: "focus:ring-blue-600 focus:ring-offset-blue-50",
    border: "border-blue-400",
  },
  warning: {
    bg: "bg-yellow-50",
    icon: "text-yellow-400",
    title: "text-yellow-800",
    text: "text-yellow-700",
    hover: "hover:bg-yellow-100",
    focus: "focus:ring-yellow-600 focus:ring-offset-yellow-50",
    border: "border-yellow-400",
  },
  error: {
    bg: "bg-red-50",
    icon: "text-red-400",
    title: "text-red-800",
    text: "text-red-700",
    hover: "hover:bg-red-100",
    focus: "focus:ring-red-600 focus:ring-offset-red-50",
    border: "border-red-400",
  },
};

const icons = {
  good: CheckCircleIcon,
  info: InformationCircleIcon,
  warning: ExclamationTriangleIcon,
  error: XCircleIcon,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// TODO: deprecate and switch to Alerts.tsx and Alert.tsx
export default function Alert({
  severity = "good",
  title = "Success!",
  subtext = "",
  showing = false,
  setShowing = () => { },
}) {
  if (!severityColors[severity]) {
    severity = "good";
  }

  return (
    <Transition.Root show={showing} as={Fragment}>
      {/* // TODO: fix z-index. showing under top nav when small screen */}
      <div className="fixed inset-0 z-50 flex flex-col items-center pointer-events-none top-5">
        <Transition.Child
          as={Fragment}
          enter="transition-transform duration-150"
          leave="transition-transform duration-75"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div
            className={classNames(
              "pointer-events-auto",
              "rounded-md p-4 border w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4",
              severityColors[severity].bg,
              severityColors[severity].border
            )}
          >
            <div className="flex">
              <div className="shrink-0">
                {createElement(icons[severity], {
                  className: classNames(
                    "h-5 w-5",
                    severityColors[severity].icon
                  ),
                  "aria-hidden": "true",
                })}
              </div>
              <div className="flex flex-col gap-2 ml-3">
                <h3
                  className={classNames(
                    "text-sm font-medium",
                    severityColors[severity].title
                  )}
                >
                  {title}
                </h3>
                {subtext && (
                  <div
                    className={classNames(
                      "text-sm",
                      severityColors[severity].text
                    )}
                  >
                    <p>{subtext}</p>
                  </div>
                )}
              </div>
              <div className="pl-3 ml-auto">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    className={classNames(
                      "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                      severityColors[severity].bg,
                      severityColors[severity].icon,
                      severityColors[severity].hover,
                      severityColors[severity].focus
                    )}
                    onClick={() => {
                      setShowing(false);
                    }}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
}
