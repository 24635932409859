import React, { Component, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ToaNav from "../components/nav/ToaNav.tsx";
import $ from "jquery";
import EasterEggs from "../components/easterEggs/EasterEggs.tsx";
import UserManager from "../tools/UserManager.js";
import UserContext from "src/contexts/user/UserContext.tsx";


export default function AppLayout() {

  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});

  // When created
  // Get user info (/api/user/info)
  // If not logged in, redirect to login page
  useEffect(() => {
    getUser();
    getCompany();
  }, []);

  /**
    * Retrieve user information
    */
  async function getUser() {
    // Get user
    try {
      var user = await UserManager.getUser()
    } catch (err) {

      navigate("/login/login");
    }
    if (!user.loggedIn) {
      navigate("/login/login");
    }
    setUser(user.userData);
  }

  /**
    * Retrieve company information
    */
  async function getCompany() {
    // Get company
    try {
      var res = await UserManager.makeAuthenticatedRequest(
        "/api/company/findmy",
        "GET"
      )
    } catch (err) {
      // TODO: what to do here?
    }
    if (!res.data.status === "ok") {
      // TODO: what to do here?
    }
    setCompany(res.data.company);
  }


  if (!user) return <>
    <div className="dark:bg-gray-700" />
  </>

  const userContext = {
    user: user,
    company: company,
    refreshUser: getUser,
    refreshCompany: getCompany,
  }

  return (
    <>
      <div className="">
        <UserContext.Provider value={userContext}>
          <EasterEggs>
            <ToaNav page="App">
              <Outlet />
            </ToaNav>
          </EasterEggs>
        </UserContext.Provider>
      </div>
    </>
  );
}
