import { TruckIcon } from 'lucide-react'
import moment from 'moment'
import pluralize from 'pluralize'
import React, { useEffect, useState } from 'react'
import Dropdown from 'src/components/input/Dropdown'
import Input from 'src/components/input/Input'
import RadioGroup from 'src/components/input/RadioGroup.tsx'
import TimeRangeInput from 'src/components/input/TimeRangeInput.tsx'
import SwitchCase from 'src/components/utils/SwitchCase.tsx'

type Props = {
  deliveryDateType: DeliveryDateTypes,
  requestedDeliveryDate: string,
  deliveryTimeRangeStart: string,
  deliveryTimeRangeEnd: string,
  selectedRelativeDateOption: "1-day" | "2-day" | "3-day" | ""; // TODO: could fully flush this out or make enum. Not necessary for quick dev.
  installationDate: string

  onDeliveryDateTypeChange?: (value: DeliveryDateTypes) => void,
  onRequestedDeliveryDateChange?: (value: string) => void,
  onDeliveryTimeRangeStartChange?: (value: string) => void,
  onDeliveryTimeRangeEndChange?: (value: string) => void,
  onSelectedRelativeDateOptionChange?: (value: string) => void
}

export enum DeliveryDateTypes {
  DAY_OF = 'day_of',
  SPECIFIED = 'specified',
  RELATIVE = 'relative'
}

// TODO: comment
export default function DeliveryDateInput({
  deliveryDateType: deliveryDateType_prop,
  requestedDeliveryDate: requestedDeliveryDate_prop,
  deliveryTimeRangeStart: deliveryTimeRangeStart_prop,
  deliveryTimeRangeEnd: deliveryTimeRangeEnd_prop,
  selectedRelativeDateOption: selectedRelativeDateOption_prop,

  onDeliveryDateTypeChange = () => { },
  onRequestedDeliveryDateChange = () => { },
  onDeliveryTimeRangeStartChange = () => { },
  onDeliveryTimeRangeEndChange = () => { },
  onSelectedRelativeDateOptionChange = () => { },

  installationDate,
}: Props) {
  const [deliveryDateType, setDeliveryDateType] = useState<DeliveryDateTypes>(DeliveryDateTypes.DAY_OF)

  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<string>('')

  const [deliveryTimeRangeStart, setDeliveryTimeRangeStart] = useState<string>('')
  const [deliveryTimeRangeEnd, setDeliveryTimeRangeEnd] = useState<string>('')

  const [selectedRelativeDateOption, setSelectedRelativeDateOption] = useState<string>('')

  // --- Sync to props --- //
  useEffect(() => setDeliveryDateType(deliveryDateType_prop), [deliveryDateType_prop])
  useEffect(() => setRequestedDeliveryDate(requestedDeliveryDate_prop), [requestedDeliveryDate_prop])
  useEffect(() => setDeliveryTimeRangeStart(deliveryTimeRangeStart_prop), [deliveryTimeRangeStart_prop])
  useEffect(() => setDeliveryTimeRangeEnd(deliveryTimeRangeEnd_prop), [deliveryTimeRangeEnd_prop])
  useEffect(() => setSelectedRelativeDateOption(selectedRelativeDateOption_prop), [selectedRelativeDateOption_prop])
  // --------------------- //

  // Radio group options for delivery date type
  // Specified means a date value is needed.
  // Relative/in advance means the delivery date is
  //   relative to the installation date
  const dateTypeOptions = [
    { id: DeliveryDateTypes.DAY_OF, label: 'Day of Installation' },
    // TODO: not using "Days in Advance" for now
    // { id: DeliveryDateTypes.RELATIVE, label: 'Days in Advance' },
    { id: DeliveryDateTypes.SPECIFIED, label: 'Specified Date' },
  ]

  // Dropdown options for relative date options
  // Value format: '<value>-<unit>;HH:mm-HH:mm'; (if no time, assume 00:00-23:59)
  // ---
  // Generate options for 1, 2, 3 days prior
  const relativeDateOptions = [
    Array(3)
      .fill(null)
      .map((_, i) => i + 1) // Map to [1, 2, 3]
      .map(num => {

        // Calculate date
        let wouldBeDate = installationDate ? moment(installationDate).subtract(num, 'days').format('ddd M/D') : null;
        return ({
          label: `${pluralize("day", num, true)} prior${wouldBeDate ? ` (${wouldBeDate})` : ''}`,
          value: `${num}-day`
        })
      })
  ]

  /**
   * Updates the internal state for delivery time range start and end
   * Format: `HH:mm:ss.sssZ`
   *
   * Also calls the prop functions to update the parent state
   */
  function onTimeRangeChange({ start, end }) {
    // convert startTime and endTime to date time string f
    // they are currently in the format "HH:MM", so we need to add the date and local timezone
    // format it as "HH:mm:ss.sssZ" with moment
    setDeliveryTimeRangeStart(moment(start, "HH:mm").format("HH:mm:ss.sssZ"))
    setDeliveryTimeRangeEnd(moment(end, "HH:mm").format("HH:mm:ss.sssZ"))

    onDeliveryTimeRangeStartChange(moment(start, "HH:mm").format("HH:mm:ss.sssZ"))
    onDeliveryTimeRangeEndChange(moment(end, "HH:mm").format("HH:mm:ss.sssZ"))
  }

  return (
    <div>
      <div className="flex items-center gap-2">
        <TruckIcon className="text-gray-700 stroke-2 w-7 h-7" />
        <div className="text-sm font-medium text-gray-700">Delivery Options</div>
        <div className="ml-5">
          <RadioGroup
            name="dateType"
            selectedId={deliveryDateType}
            options={dateTypeOptions}
            onChange={(optionId) => {
              setSelectedRelativeDateOption(''); // Deselect relative date option
              setDeliveryDateType(optionId as DeliveryDateTypes)
              setDeliveryTimeRangeStart('')
              setDeliveryTimeRangeEnd('')

              if (optionId === DeliveryDateTypes.DAY_OF) {
                // Set delivery date to installation date
                setRequestedDeliveryDate(installationDate)
                onRequestedDeliveryDateChange(installationDate)
              } else {
                // Clear requested delivery date
                setRequestedDeliveryDate('')
                onRequestedDeliveryDateChange('')
              }

              onDeliveryTimeRangeStartChange('')
              onDeliveryTimeRangeEndChange('')
              onSelectedRelativeDateOptionChange('')
              onDeliveryDateTypeChange(optionId as DeliveryDateTypes)
            }}
          />
        </div>
      </div>
      <SwitchCase test={deliveryDateType}>
        {/* Day of */}
        <div data-case={DeliveryDateTypes.DAY_OF} className="flex items-center justify-center pt-3">
          <TimeRangeInput
            startTime={deliveryTimeRangeStart ? moment(deliveryTimeRangeStart, "HH:mm:ss.sssZ").format("HH:mm") : ""}
            endTime={deliveryTimeRangeEnd ? moment(deliveryTimeRangeEnd, "HH:mm:ss.sssZ").format("HH:mm") : ""}
            onChange={onTimeRangeChange}
          />
        </div>

        {/* Relative */}
        <div data-case={DeliveryDateTypes.RELATIVE} className="pt-3">
          <Dropdown
            justifyLeft
            wide
            label="Select a day in advance of the installation"
            options={relativeDateOptions}
            selectedValue={selectedRelativeDateOption}
            onSelected={(option) => {

              // Set delivery date accordingly
              let [dateOffset, timeRange] = option.value.split(';')
              let [amount, unit] = dateOffset.split('-')
              let [startTime, endTime] = (timeRange ?? "00:00-23:59").split('-');

              const resultingDate = moment(installationDate).subtract(amount, unit).format("YYYY-MM-DD")
              const resultingStart = moment(startTime, "HH:mm").format("HH:mm:ss.sssZ")
              const resultingEnd = moment(endTime, "HH:mm").format("HH:mm:ss.sssZ")

              setSelectedRelativeDateOption(option.value)
              setRequestedDeliveryDate(resultingDate)
              setDeliveryTimeRangeStart(resultingStart)
              setDeliveryTimeRangeEnd(resultingEnd)

              onSelectedRelativeDateOptionChange(option.value)
              onRequestedDeliveryDateChange(resultingDate)
              onDeliveryTimeRangeStartChange(resultingStart)
              onDeliveryTimeRangeEndChange(resultingEnd)
            }}
          />
        </div>

        {/* Specified */}
        <div data-case={DeliveryDateTypes.SPECIFIED}>
          <div className="flex justify-between gap-4 pt-3">
            <div className="grow">
              <Input
                type="date"
                label="Requested Delivery Date"
                name="requestedDeliveryDate"
                value={requestedDeliveryDate}
                onChange={(value) => {
                  let formatted = value ? moment(value).format("YYYY-MM-DD") : value;
                  // right now value is formatted as "YYYY-MM-DD", use moment to format it in date time string format, i.e. "YYYY-MM-DDTHH:mm:ss.sssZ"
                  setRequestedDeliveryDate(formatted)

                  onRequestedDeliveryDateChange(formatted)
                }}
              />
            </div>
            <div className="grow">
              <TimeRangeInput
                justifyRight
                startTime={deliveryTimeRangeStart ? moment(deliveryTimeRangeStart, "HH:mm:ss.sssZ").format("HH:mm") : ""}
                endTime={deliveryTimeRangeEnd ? moment(deliveryTimeRangeEnd, "HH:mm:ss.sssZ").format("HH:mm") : ""}
                enforceStartBeforeEnd
                onChange={onTimeRangeChange}
                label="Time Range"
              />
            </div>
          </div>
        </div>

      </SwitchCase>
    </div>
  )
}

/**
  * Turns the relative delivery date information of an order into a string (e.g. "1-day")
  * Returns null if no relative date information is provided
  */
export function getRelativeDateOption(relativeToInstall: {
  // Number of "unit"s to subtract
  amount: Number;
  // Unit to use for subtracting from installation date
  unit: "day" | "week" | "month";
}): string {
  if (!relativeToInstall) return null;

  return `${relativeToInstall.amount}-${relativeToInstall.unit}`
}

/**
  * Formats date to string (YYYY-MM-DD)
  */
export function dateToString(date: Date) {
  return moment(date).utc().format("YYYY-MM-DD");
}

/**
  * Formats time to string (HH:mm:ss.sssZ)
  */
export function timeToString(time: Date): string {
  return time ? moment(time).format("HH:mm:ss.sssZ") : ""
}
