import React, { createContext, useContext, useReducer } from 'react';
import { User } from 'src/hooks/data/users/useUsersByIds';

type UserContextType = {
  user: User | null;
  company: Company | null;
  reloadUser: () => void;
  reloadCompany: () => void;
}

type Company = Installer | Distributor;

type Installer = {
  _id: string;
  name: string;
}

const UserContext = createContext<UserContextType>({
  user: null,
  company: null,
  reloadUser: () => { },
  reloadCompany: () => { },
});

export default UserContext;
